import {
  InvestorCoverageRead,
  PaginationMetadata,
} from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes } from '@cmg/common';
import React from 'react';

import { InvestorCoverageFilters } from '../../../../../common/api/rolodexApiClient';
import { AccountType } from '../../../../../types/domain/account/constants';
import { Profile, ProfileUpdate } from '../../../../../types/domain/self/profile';
import { InvestorCoverageSection } from '../../../../design-system/shared/investor-coverage/InvestorCoverageSection';
import { HangfireLinksSection } from './HangfireLinksSection';
import { LoginDetailsSection } from './LoginDetailsSection';
import { ProfileDetailsSection } from './ProfileDetailsSection';

export type Props = {
  oidcUserAccountType: AccountType | 'SYSTEM' | null;
  profile: Profile | null;
  isProfileSubmitting: boolean;
  onProfileSubmit: (data: ProfileUpdate) => void;
  profileSubmitError: apiTypes.GenericServerError | null;
  oidcUserIdp: string | null;
  showInvestorCoverage: boolean;
  showHangfireLinks: boolean;
  isLoadingInvestorCoverage: boolean;
  investorCoverage: InvestorCoverageRead[];
  investorCoveragePagination: PaginationMetadata | null;
  investorCoverageFilters: InvestorCoverageFilters;
  onInvestorCoverageChangePage: (params: apiTypes.ListParams) => void;
  onInvestorCoverageChangeFilters: (params: InvestorCoverageFilters, debounce?: boolean) => void;
};

export const ProfileGridContent: React.VFC<Props> = ({
  profile,
  isProfileSubmitting,
  onProfileSubmit,
  profileSubmitError,
  oidcUserIdp,
  oidcUserAccountType,
  showInvestorCoverage,
  showHangfireLinks,
  isLoadingInvestorCoverage,
  investorCoverage,
  investorCoveragePagination,
  investorCoverageFilters,
  onInvestorCoverageChangePage,
  onInvestorCoverageChangeFilters,
}) => {
  return (
    <React.Fragment>
      {profile && oidcUserIdp && (
        <ProfileDetailsSection
          submitError={profileSubmitError}
          isSubmitting={isProfileSubmitting}
          oidcUserAccountType={oidcUserAccountType}
          profile={profile}
          onSubmit={onProfileSubmit}
        />
      )}
      {oidcUserIdp === 'local' && <LoginDetailsSection />}
      {showInvestorCoverage && investorCoveragePagination && (
        <InvestorCoverageSection
          isLoading={isLoadingInvestorCoverage}
          investorCoverage={investorCoverage}
          pagination={investorCoveragePagination}
          filters={investorCoverageFilters}
          onChangeFilters={onInvestorCoverageChangeFilters}
          onChangePage={onInvestorCoverageChangePage}
        />
      )}
      {showHangfireLinks && <HangfireLinksSection />}
    </React.Fragment>
  );
};
