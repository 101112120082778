import {
  AdminFirmBasicInformationRead,
  AdminFirmBasicInformationUpdate,
  AdminFirmLimitedReadPagedList,
  CrmCsvImportResult,
  FirmCategoryType,
  InvestorCoverageReadPagedList,
  InvestorFirmAdminRead as CrmInvestorFirm,
} from '@capital-markets-gateway/api-client-rolodex';
import { accessTokenExpirationRequestInterceptor } from '@cmg/auth';
import { apiTypes, apiUtil, UnpackPromise, urlUtil } from '@cmg/common';
import axios, { AxiosResponse } from 'axios';

import { CRMFilesUploadFormValues } from '../../features/rolodex/crm-management/crm-files-upload/grid-content/CRMFilesUploadSection.model';
import { UUID } from '../../types/common';
import { crmFileTypeLabelsLowerCase } from '../../types/domain/crm/constants';
import { LinkedStatus } from '../../types/domain/entity-matcher/constants';
import { CrmIntegration } from '../../types/domain/entity-matcher/CrmIntegration';
import { FirmNameType, FirmRoleType, TraversalType } from '../../types/domain/firm/constants';
import { FactSetFirmHierarchy } from '../../types/domain/firm/FactSetFirmHierarchy';
import { FactSetFirmLimited } from '../../types/domain/firm/FactSetFirmLimited';
import { Firm } from '../../types/domain/firm/Firm';
import { CreateFirmAddress, FirmAddress } from '../../types/domain/firm/FirmAddress';
import { CreateFirmContact, FirmContact } from '../../types/domain/firm/FirmContact';
import { FirmHierarchy } from '../../types/domain/firm/FirmHierarchy';
import { CreateFirmIdentifier, FirmIdentifier } from '../../types/domain/firm/FirmIdentifier';
import { FirmLimited } from '../../types/domain/firm/FirmLimited';
import { CreateFirmName, FirmName } from '../../types/domain/firm/FirmName';
import { FirmRelation } from '../../types/domain/firm/FirmRelation';
import { CreateFirmRole, FirmRole } from '../../types/domain/firm/FirmRole';
import { CreateFirmSector, FirmSector } from '../../types/domain/firm/FirmSector';
import { FirmVersion } from '../../types/domain/firm/FirmVersion';
import { TransformedFirm } from '../../types/domain/firm/TransformedFirm';
import { FirmMatch } from '../../types/domain/firm-match/FirmMatch';
import { FirmMatchSource } from '../../types/domain/firm-match/FirmMatchSource';
import { FirmStatus } from '../../types/domain/firm-status/FirmStatus';
import { RecordStatus } from '../../types/domain/record-status/RecordStatus';
import { RolodexMetadata } from '../../types/domain/rolodex/Metadata';
import { accessTokenRequestInterceptor, responseErrorInterceptor } from './interceptors';
import { getExcelDownload } from './util/axiosDownloadUtil';
/**
 * The Rolodex client
 **/
const client = axios.create({
  baseURL: '/dex/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(accessTokenExpirationRequestInterceptor);
client.interceptors.request.use(accessTokenRequestInterceptor);
client.interceptors.response.use(undefined, responseErrorInterceptor);

/**
 * API Calls
 */

/**
 * Retrieve a list of CMG firms
 */
export type FirmListFilters = {
  /** legalEntityType - filter firms by provided value for legal entity type */
  legalEntityType?: string | null;
  /** industryType - filter firms by provided value for industry type */
  industryType?: string | null;
  /** roleType - filter firms by firm role */
  roleType?: FirmRoleType | null;
  /** searchText - filter firms by name */
  searchText?: string | null;
  /** key - filter firms by key/CMG ID (a simple, int-based ID given to each firm) */
  key?: string | null;
  /** status - filter firms by status - Active or Defunct */
  status?: FirmStatus | null;
  /** deletionStatus - filter firms by record/deletion status - Effective or Deleted */
  deletionStatus?: RecordStatus | null;
  /** firmType - filter firms by firmType */
  firmType?: string | null;
};
export type GetFirmsParams = apiTypes.ListParams & FirmListFilters;
export const getFirms = (filters: GetFirmsParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    ...filters,
  });
  return client
    .get<apiTypes.PaginatedResponse<FirmLimited[]>>(`/firms${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmsResponse = UnpackPromise<ReturnType<typeof getFirms>>;

/**
 * Create a single firm
 */
type CreateFirmParams = { type: FirmNameType; value: string };
type CreateFirmPayload = CreateFirmParams & { recordStatus: RecordStatus };
export const createFirm = (params: CreateFirmParams) => {
  return client
    .post<CreateFirmPayload, AxiosResponse<Firm>>(`/firms`, {
      ...params,
      recordStatus: RecordStatus.EFFECTIVE,
    })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmResponse = UnpackPromise<ReturnType<typeof createFirm>>;

/**
 * Retrieve a single firm
 */
export const getFirm = (firmId: UUID) => {
  return client
    .get<Firm>(`/firms/${firmId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmResponse = UnpackPromise<ReturnType<typeof getFirm>>;

/**
 * Retrieve a single firm by entity key
 */
export const getFirmByEntityKey = (cmgEntityKey: string) => {
  return client
    .get<AdminFirmLimitedReadPagedList>(`/firms?key=${cmgEntityKey}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmByEntityKeyResponse = UnpackPromise<ReturnType<typeof getFirmByEntityKey>>;

/**
 * Retrieve Rolodex legal entity type metadata - the list of possible
 * legal entity types.
 */
export const getLegalEntityTypeFirmMetadata = () => {
  return client
    .get<RolodexMetadata['entityTypes']>('/firms/metadata/legalEntityType')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetLegalEntityTypeFirmMetadataResponse = UnpackPromise<
  ReturnType<typeof getLegalEntityTypeFirmMetadata>
>;

/**
 * Retrieve Rolodex industry type metadata - the list of possible
 * industry types.
 */
export const getIndustryTypeFirmMetadata = () => {
  return client
    .get<RolodexMetadata['industryTypes']>('/firms/metadata/industryType')
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetIndustryTypeFirmMetadataResponse = UnpackPromise<
  ReturnType<typeof getIndustryTypeFirmMetadata>
>;

/**
 * Retrieve hierarchy for a single firm
 */
export const getFirmHierarchy = ({
  firmId,
  ...params
}: {
  firmId: UUID;
  traversalType?: TraversalType;
}) => {
  const querystring = urlUtil.queryStringify(params);
  return client
    .get<FirmHierarchy>(`/firms/${firmId}/hierarchy${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmHierarchyResponse = UnpackPromise<ReturnType<typeof getFirmHierarchy>>;

/**
 * Updates firm basic information
 */
export const updateFirmDetails = ({
  firmId,
  id,
  body,
}: {
  firmId: UUID;
  id: UUID;
  body: AdminFirmBasicInformationUpdate;
}) => {
  return client
    .put<AdminFirmBasicInformationUpdate, AxiosResponse<AdminFirmBasicInformationRead>>(
      `/firms/${firmId}/firmBasicInformation/${id}`,
      body
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmDetailsResponse = UnpackPromise<ReturnType<typeof updateFirmDetails>>;

/**
 * Creates a new name record on a firm
 */
export const createFirmName = ({ firmId, name }: CreateFirmName) => {
  return client
    .post<typeof name, AxiosResponse<FirmName>>(`/firms/${firmId}/names`, name)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmNameResponse = UnpackPromise<ReturnType<typeof createFirmName>>;

/**
 * Updates a name record on a firm
 */
export const updateFirmName = ({
  firmId,
  name: { id: nameId, isOverride, isDisplayName, isMarketingName, ...body },
}: {
  firmId: UUID;
  name: FirmName;
}) => {
  return client
    .put<typeof body, AxiosResponse<FirmName>>(`/firms/${firmId}/names/${nameId}`, body)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmNameResponse = UnpackPromise<ReturnType<typeof updateFirmName>>;

/**
 * Sets a firm name as the display name
 */
export const setDisplayName = ({ firmId, nameId }: { firmId: UUID; nameId: UUID }) => {
  return client
    .post(`/firms/${firmId}/names/${nameId}/setDisplayName`, {})
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type SetFirmDisplayNameResponse = UnpackPromise<ReturnType<typeof setDisplayName>>;

/**
 * Sets a firm name as the marketing name
 */
export const setMarketingName = ({
  firmId,
  nameId,
  isMarketingName,
}: {
  firmId: UUID;
  nameId: UUID;
  isMarketingName: boolean;
}) => {
  return client
    .post(`/firms/${firmId}/names/${nameId}/setMarketingName`, {
      isMarketingName,
    })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type SetFirmMarketingNameResponse = UnpackPromise<ReturnType<typeof setMarketingName>>;

/**
 * Sets a firm name as the Finra name
 */
export const setFinraName = ({
  firmId,
  nameId,
  isFinraName,
}: {
  firmId: UUID;
  nameId: UUID;
  isFinraName: boolean;
}) => {
  return client
    .post(`/firms/${firmId}/names/${nameId}/setFinraName`, {
      isFinraName,
    })
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type SetFirmFinraNameResponse = UnpackPromise<ReturnType<typeof setFinraName>>;

/**
 * Creates a new contact record on a firm
 */
export const createFirmContact = ({ firmId, contact }: CreateFirmContact) => {
  return client
    .post<typeof contact, AxiosResponse<FirmContact>>(`/firms/${firmId}/contacts`, contact)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmContactResponse = UnpackPromise<ReturnType<typeof createFirmContact>>;

/**
 * Updates a contact record on a firm
 */
export const updateFirmContact = ({
  firmId,
  contact: { id: contactId, isOverride, ...body },
}: {
  firmId: UUID;
  contact: FirmContact;
}) => {
  return client
    .put<typeof body, AxiosResponse<FirmContact>>(`/firms/${firmId}/contacts/${contactId}`, body)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmContactResponse = UnpackPromise<ReturnType<typeof updateFirmContact>>;

/**
 * Creates a new sector record on a firm
 */
export const createFirmSector = ({ firmId, sector }: CreateFirmSector) => {
  return client
    .post<typeof sector, AxiosResponse<FirmSector>>(`/firms/${firmId}/sectors`, sector)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmSectorResponse = UnpackPromise<ReturnType<typeof createFirmSector>>;

/**
 * Updates a sector record on a firm
 */
export const updateFirmSector = ({
  firmId,
  sector: { id: sectorId, isOverride, ...body },
}: {
  firmId: UUID;
  sector: FirmSector;
}) => {
  return client
    .put<typeof body, AxiosResponse<FirmSector>>(`/firms/${firmId}/sectors/${sectorId}`, body)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmSectorResponse = UnpackPromise<ReturnType<typeof updateFirmSector>>;

/**
 * Creates a new address record on a firm
 */
export const createFirmAddress = ({ firmId, address }: CreateFirmAddress) => {
  return client
    .post<typeof address, AxiosResponse<FirmAddress>>(`/firms/${firmId}/addresses`, address)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmAddressResponse = UnpackPromise<ReturnType<typeof createFirmAddress>>;

/**
 * Updates a address record on a firm
 */
export const updateFirmAddress = ({
  firmId,
  address: { id: addressId, isOverride, ...body },
}: {
  firmId: UUID;
  address: FirmAddress;
}) => {
  return client
    .put<typeof body, AxiosResponse<FirmAddress>>(`/firms/${firmId}/addresses/${addressId}`, body)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmAddressResponse = UnpackPromise<ReturnType<typeof updateFirmAddress>>;

/**
 * Creates a new identifier record on a firm
 */
export const createFirmIdentifier = ({ firmId, identifier }: CreateFirmIdentifier) => {
  return client
    .post<typeof identifier, AxiosResponse<FirmIdentifier>>(
      `/firms/${firmId}/identifiers`,
      identifier
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmIdentifierResponse = UnpackPromise<ReturnType<typeof createFirmIdentifier>>;

/**
 * Updates a identifier record on a firm
 */
export const updateFirmIdentifier = ({
  firmId,
  identifier: { id: identifierId, isOverride, ...body },
}: {
  firmId: UUID;
  identifier: FirmIdentifier;
}) => {
  return client
    .put<typeof body, AxiosResponse<FirmIdentifier>>(
      `/firms/${firmId}/identifiers/${identifierId}`,
      body
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmIdentifierResponse = UnpackPromise<ReturnType<typeof updateFirmIdentifier>>;

/**
 * Creates a new role record on a firm
 */
export const createFirmRole = ({ firmId, role }: CreateFirmRole) => {
  return client
    .post<typeof role, AxiosResponse<FirmRole>>(`/firms/${firmId}/roles`, role)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CreateFirmRoleResponse = UnpackPromise<ReturnType<typeof createFirmRole>>;

/**
 * Updates a role record on a firm
 */
export const updateFirmRole = ({
  firmId,
  role: { id: roleId, isOverride, ...body },
}: {
  firmId: UUID;
  role: FirmRole;
}) => {
  return client
    .put<typeof body, AxiosResponse<FirmRole>>(`/firms/${firmId}/roles/${roleId}`, body)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateFirmRoleResponse = UnpackPromise<ReturnType<typeof updateFirmRole>>;

/**
 * Sets the parent firm of a firm
 */
export const setFirmParent = ({
  firmId,
  relationId,
  parentId,
}: {
  firmId: UUID;
  relationId: UUID;
  parentId: UUID | null;
}) => {
  return client
    .put<{ parentId: typeof parentId }, AxiosResponse<FirmRelation>>(
      `/firms/${firmId}/relations/${relationId}/parent`,
      { parentId }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type SetFirmParentResponse = UnpackPromise<ReturnType<typeof setFirmParent>>;

/**
 * Retrieve the list of changes/version history of a firm
 */
export const getFirmVersionHistory = (firmId: UUID) => {
  return client
    .get<FirmVersion[]>(`/firms/${firmId}/versions`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmVersionHistoryResponse = UnpackPromise<ReturnType<typeof getFirmVersionHistory>>;

/**
 * Retrieve a specific version of a Firm
 */
export const getFirmVersion = (firmId: UUID, version: number) => {
  return client
    .get<Firm>(`/firms/${firmId}/version/${version}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmVersionResponse = UnpackPromise<ReturnType<typeof getFirmVersion>>;

/**
 * Note: Currently, a FactSet database does not exist in the local
 * docker setup.  Instead, we point to the FactSet databases that
 * exist on staging.  For the following FactSet endpoints to function,
 * you'll need to add some additional configuration settings to your
 * DotNet app settings file in the local docker codebase.
 */

/**
 * Import a firm from FactSet
 */
export const importFactSetFirm = (params: { factsetId: UUID }) => {
  return client
    .post<typeof params, AxiosResponse<Firm>>(`/firms/fromFactSet`, params)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ImportFactSetFirmResponse = UnpackPromise<ReturnType<typeof importFactSetFirm>>;

/**
 * Retrieve a list of FactSet firms
 */
export type FactSetFirmListFilters =
  /** searchText - filter FactSet Firms by name */
  /** ids[] - filter FactSet Firms by an ID.  Note: Multiple ids[]
   *    can be provided in the query string, e.g. ids[]=id1&ids[]=id2
   */
  /** Either searchText or id can be provided - not both. */
  | {
      searchText?: string;
      'ids[]'?: never;
    }
  | {
      searchText?: never;
      'ids[]'?: string[];
    };
export type GetFactSetFirmsParams = apiTypes.ListParams & FactSetFirmListFilters;
export const getFactSetFirms = (params: GetFactSetFirmsParams) => {
  const querystring = urlUtil.queryStringify(params);
  return client
    .get<apiTypes.PaginatedResponse<FactSetFirmLimited[]>>(`/factSetFirms${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFactSetFirmsResponse = UnpackPromise<ReturnType<typeof getFactSetFirms>>;

/**
 * Retrieve hierarchy for a FactSet firm
 */
export const getFactSetFirmHierarchy = ({ firmId }: { firmId: string }) => {
  return client
    .get<FactSetFirmHierarchy>(`/factSetFirms/${firmId}/ancestors`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFactSetFirmHierarchyResponse = UnpackPromise<
  ReturnType<typeof getFactSetFirmHierarchy>
>;

/**
 * Given a a FactSet Firm ID, returns a preview of the firm
 * as it would exist as a CMG Entity.
 */
export const getFactSetFirm = (factSetFirmId: string) => {
  return client
    .get<TransformedFirm>(`/factSetFirms/${factSetFirmId}/preview`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFactSetFirmResponse = UnpackPromise<ReturnType<typeof getFactSetFirm>>;

/**
 * Connects FactSet to a CMG Firm and imports FactSet data into
 * the CMG Firm - or returns a preview of the CMG Firm with the
 * imported FactSet data.
 *
 * When the isDryRun param is true, the endpoint returns a preview of the firm
 * as it would exist as a CMG Entity. When it is false, the connection is created.
 */
export const connectFactSetFirmWithCMGFirm = (params: {
  cmgFirmId: UUID;
  factSetFirmId: string;
  isDryRun: boolean;
}) => {
  return client
    .post<{ factSetId: string }, AxiosResponse<Firm>>(
      `firms/${params.cmgFirmId}/factSetLink?isDryRun=${params.isDryRun}`,
      {
        factSetId: params.factSetFirmId,
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type ConnectFactSetFirmToCMGFirmResponse = UnpackPromise<
  ReturnType<typeof connectFactSetFirmWithCMGFirm>
>;

/**
 * Disconnects a FactSet Firm from a CMG Firm, or returns
 * a preview of the changes to the CMG Firm if disconnected
 * from FactSet.
 *
 * When the isDryRun param is true, the endpoint returns a
 * preview of the firm as it would exist disconnected from
 * FactSet. When it is false, the FactSet link is broken.
 */
export const disconnectFactSetFirmFromCMGFirm = (params: { firmId: UUID; isDryRun: boolean }) => {
  return client
    .delete(`firms/${params.firmId}/factSetLink?isDryRun=${params.isDryRun}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type DisconnectFactSetFirmFromCMGFirmResponse = UnpackPromise<
  ReturnType<typeof disconnectFactSetFirmFromCMGFirm>
>;

/**
 * Retrieve a list of CRM integrations
 */
export type GetCrmIntegrationsParams = apiTypes.ListParams;
export const getCrmIntegrations = (params: GetCrmIntegrationsParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    ...params,
  });
  return client
    .get<apiTypes.PaginatedResponse<CrmIntegration[]>>(`/admin/crm/accounts${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetCrmIntegrationsResponse = UnpackPromise<ReturnType<typeof getCrmIntegrations>>;

/**
 * Retrieve a single CRM Integration
 */
export type GetCrmIntegrationParams = { crmIntegrationId: UUID };
export const getCrmIntegration = ({ crmIntegrationId }: GetCrmIntegrationParams) => {
  return client
    .get<CrmIntegration>(`/admin/crm/accounts/${crmIntegrationId}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetCrmIntegrationResponse = UnpackPromise<ReturnType<typeof getCrmIntegration>>;

/**
 * Retrieve a list of CRM Investor Firms from a CRM Integration
 */
export type CrmInvestorFirmsFilters = {
  /** searchText - filter CRM Investor Firms by name */
  searchText?: string | null;
  /** linkedStatus - filter CRM Investor Firms by link status - linked or unlinked  */
  linkedStatus?: LinkedStatus | null;
  /** firmType - filter CRM Investor by firm type - Regular - PWM - Free text */
  firmType?: FirmCategoryType | null;
  /** firmStatus - filter CRM Investor by firm status */
  firmStatus?: RecordStatus | null;
};
export type GetCrmInvestorFirmsParams = { crmIntegrationId: UUID } & CrmInvestorFirmsFilters &
  apiTypes.ListParams;
export const getCrmInvestorFirms = ({ crmIntegrationId, ...params }: GetCrmInvestorFirmsParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    ...params,
  });
  return client
    .get<{
      data: CrmInvestorFirm[];
      summary: CrmIntegration;
      pagination: apiTypes.Pagination;
    }>(`/admin/crm/accounts/${crmIntegrationId}/investorFirms${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetCrmInvestorFirmsResponse = UnpackPromise<ReturnType<typeof getCrmInvestorFirms>>;

export const downloadCrmInvestorFirms = (crmIntegrationId: UUID) => {
  return getExcelDownload({
    client,
    url: `/admin/crm/accounts/${crmIntegrationId}/investorFirms/export`,
  });
};
export type DownloadCrmInvestorFirmsResponse = UnpackPromise<
  ReturnType<typeof downloadCrmInvestorFirms>
>;

/**
 * Updates a CRM Investor Firm Link
 */
export const updateInvestorFirmLink = (
  accountId: string,
  investorFirmId: string,
  cmgEntityKey: string | null
) => {
  return client
    .put<{ cmgEntityKey: string | null }, AxiosResponse<void>>(
      `/admin/crm/accounts/${accountId}/investorFirms/${investorFirmId}/cmgEntityKey`,
      {
        cmgEntityKey,
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateInvestorFirmLinkResponse = UnpackPromise<
  ReturnType<typeof updateInvestorFirmLink>
>;

/**
 * Retrieve a single CRM Investor Firm
 */
export type GetCrmInvestorFirmParams = { crmIntegrationId: UUID; crmInvestorFirmId: UUID };
export const getCrmInvestorFirm = ({
  crmIntegrationId,
  crmInvestorFirmId,
}: GetCrmInvestorFirmParams) => {
  return client
    .get<CrmInvestorFirm>(
      `/admin/crm/accounts/${crmIntegrationId}/investorFirms/${crmInvestorFirmId}`
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetCrmInvestorFirmResponse = UnpackPromise<ReturnType<typeof getCrmInvestorFirm>>;

/**
 * Retrieve a list of Firm Matches by CrmInvestoFirm and CrmIngtegration
 */
export type GetCrmInvestorFirmMatchesParams = {
  crmIntegrationId: UUID;
  crmInvestorFirmId: UUID;
  source?: FirmMatchSource;
  perPage?: number;
  orderField?: string;
};

export const getCrmInvestorFirmMatches = ({
  crmIntegrationId,
  crmInvestorFirmId,
  ...params
}: GetCrmInvestorFirmMatchesParams) => {
  const querystring = urlUtil.queryStringify(params);
  return client
    .get<FirmMatch[]>(`/firmMatcher/${crmIntegrationId}/${crmInvestorFirmId}${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetCrmInvestorFirmMatchesResponse = UnpackPromise<
  ReturnType<typeof getCrmInvestorFirmMatches>
>;

/**
 * Update notes on a CRM Investor Firm
 */
export const updateCrmInvestorNotes = (
  crmIntegrationId: UUID,
  crmInvestorFirmId: UUID,
  notes: string | null
) => {
  return client
    .put<{ notes: string | null }, AxiosResponse<void>>(
      `/admin/crm/accounts/${crmIntegrationId}/investorFirms/${crmInvestorFirmId}/notes`,
      {
        notes,
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type UpdateCrmInvestorNotesResponse = UnpackPromise<
  ReturnType<typeof updateCrmInvestorNotes>
>;

/**
 * Retrieve a list of custom Firm Matches based on search query params
 */
export type GetCustomFirmMatchesFilters = {
  rolodexKey?: UUID;
  name?: string;
  address?: string;
  webDomain?: string;
  lei?: string;
  crd?: string;
  cik?: string;
};
export type GetCustomFirmMatchesParams = {
  crmIntegrationId: UUID;
  crmInvestorFirmId: UUID;
  source?: FirmMatchSource;
  perPage?: number;
  orderField?: string;
} & GetCustomFirmMatchesFilters;
export const getFirmMatches = ({
  crmIntegrationId,
  crmInvestorFirmId,
  ...params
}: GetCustomFirmMatchesParams) => {
  const querystring = urlUtil.queryStringify(params);
  return client
    .get<FirmMatch[]>(`/firmMatcher${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetCustomFirmMatchesResponse = UnpackPromise<ReturnType<typeof getFirmMatches>>;

/**
 * Retrieve a single linked firm match given its cmgEntityKey
 */
export type GetFirmMatchParams = { cmgEntityKey: string };
export const getFirmMatch = ({ cmgEntityKey }: GetFirmMatchParams) => {
  return client
    .get<FirmMatch>(`/firmMatcher/${cmgEntityKey}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type GetFirmMatchResponse = UnpackPromise<ReturnType<typeof getFirmMatch>>;

/**
 * Fetch Investor Coverage information to be displayed on the profile page
 */
export type InvestorCoverageFilters = {
  searchText?: string;
  coverageStatus?: RecordStatus;
};
export type GetInvestorCoverageParams = apiTypes.ListParams & InvestorCoverageFilters;
export const fetchInvestorCoverage = (params: GetInvestorCoverageParams) => {
  const querystring = urlUtil.queryStringify({
    includeTotals: true,
    ...params,
  });
  return client
    .get<InvestorCoverageReadPagedList>(`crm/investorCoverage${querystring}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type FetchInvestorCoverageResponse = UnpackPromise<ReturnType<typeof fetchInvestorCoverage>>;

/**
 * Fetch Investor Coverage information for specific employee
 */
export type EmployeeInvestorCoverageFilters = {
  searchText?: string;
  coverageStatus?: RecordStatus;
};
export type GetEmployeeInvestorCoverageParams = apiTypes.ListParams & {
  employeeKeyOrId: UUID;
} & EmployeeInvestorCoverageFilters;
export const fetchEmployeeInvestorCoverage = ({
  employeeKeyOrId,
  ...params
}: GetEmployeeInvestorCoverageParams) => {
  const queryString = urlUtil.queryStringify({ includeTotals: true, ...params });
  return client
    .get<InvestorCoverageReadPagedList>(`crm/investorCoverage/${employeeKeyOrId}${queryString}`)
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type FetchEmployeeInvestorCoverageResponse = UnpackPromise<
  ReturnType<typeof fetchEmployeeInvestorCoverage>
>;

/**
 * Bulk Import from CSV file - Customer Admin
 */
export const customerAdminBulkImportRequest = (
  _accountSubdomain: string,
  params: CRMFilesUploadFormValues
) => {
  return client
    .post<CRMFilesUploadFormValues, AxiosResponse<CrmCsvImportResult>>(
      `/crm/bulkImport/${crmFileTypeLabelsLowerCase[params.crmFileType]}File`,
      { file: params.file, skip: params.skip },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type CustomerAdminBulkImportFromCsvFileResponse = UnpackPromise<
  ReturnType<typeof customerAdminBulkImportRequest>
>;

/**
 * Bulk Import from CSV file - System Admin
 */
export const sysAdminBulkImportRequest = (
  accountSubdomain: string,
  params: CRMFilesUploadFormValues
) => {
  return client
    .post<CRMFilesUploadFormValues, AxiosResponse<CrmCsvImportResult>>(
      `/crm/admin/${accountSubdomain}/bulkImport/${
        crmFileTypeLabelsLowerCase[params.crmFileType]
      }File`,
      { file: params.file, skip: params.skip },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(apiUtil.transformSuccessResponse)
    .catch(apiUtil.transformFailureResponse);
};
export type SysAdminBulkImportFromCsvFileResponse = UnpackPromise<
  ReturnType<typeof sysAdminBulkImportRequest>
>;

const rolodexApiClient = {
  customerAdminBulkImportRequest,
  connectFactSetFirmWithCMGFirm,
  createFirm,
  createFirmAddress,
  createFirmContact,
  createFirmIdentifier,
  createFirmName,
  createFirmRole,
  createFirmSector,
  disconnectFactSetFirmFromCMGFirm,
  fetchInvestorCoverage,
  fetchEmployeeInvestorCoverage,
  getCrmIntegration,
  getCrmIntegrations,
  getCrmInvestorFirm,
  getCrmInvestorFirmMatches,
  getCrmInvestorFirms,
  getFactSetFirm,
  getFactSetFirmHierarchy,
  getFactSetFirms,
  getFirm,
  getFirmByEntityKey,
  getFirmMatch,
  getFirmMatches,
  getFirms,
  getFirmHierarchy,
  getFirmVersion,
  getFirmVersionHistory,
  getIndustryTypeFirmMetadata,
  getLegalEntityTypeFirmMetadata,
  importFactSetFirm,
  setFirmParent,
  sysAdminBulkImportRequest,
  updateFirmAddress,
  updateFirmContact,
  updateFirmDetails,
  updateFirmIdentifier,
  updateFirmName,
  updateFirmRole,
  updateFirmSector,
  updateInvestorFirmLink,
  updateCrmInvestorNotes,
};

export default rolodexApiClient;
