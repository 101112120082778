import { Button } from '@cmg/design-system';
import { VFC } from 'react';

import externalRouteFactory from '../../../../../common/util/externalRouteFactory';
import { DescriptionList } from '../../../../../design-system/body-sections/description-list/DescriptionList';
import { DescriptionListItem } from '../../../../../design-system/body-sections/description-list/DescriptionListItem';
import { PageGridSection } from '../../../../../design-system/body-sections/PageGridSection';

export const HangfireLinksSection: VFC = () => {
  return (
    <PageGridSection
      title="Hangfire"
      content={
        <DescriptionList>
          <DescriptionListItem
            label="Rolodex"
            value={
              <Button href={externalRouteFactory.rolodexHangfire.getUrl()}>
                Rolodex Hangfire Dashboard
              </Button>
            }
          />
          <DescriptionListItem
            label="OrderBooks"
            value={
              <Button href={externalRouteFactory.orderBooksHangfire.getUrl()}>
                OrderBooks Hangfire Dashboard
              </Button>
            }
          />
          <DescriptionListItem
            label="Wires"
            value={
              <Button href={externalRouteFactory.wiresHangfire.getUrl()}>
                Wires Hangfire Dashboard
              </Button>
            }
          />
          <DescriptionListItem
            label="DataLab"
            value={
              <Button href={externalRouteFactory.dataLabHangfire.getUrl()}>
                DataLab Hangfire Dashboard
              </Button>
            }
          />
        </DescriptionList>
      }
    />
  );
};
