import { urlUtil } from '@cmg/common';

import { getAppSettings } from '../config/appSettings';

const getSystemApiUrl = () => {
  const appSettings = getAppSettings();
  return urlUtil.addSubdomainToUrl(appSettings.env.ecmSpaBaseUrl, 'system.api');
};

/**
 * Instead of hard coding external URLs that our app links to, we define them in
 * a common location.
 */
const externalRouteFactory = {
  ecmSpa: {
    getUrl: () => {
      const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
      return urlUtil.addSubdomainToUrl(getAppSettings().env.ecmSpaBaseUrl, accountSubdomain);
    },
  },
  developerDocsSite: {
    getUrl: () => {
      const appSettings = getAppSettings();
      return appSettings.env.developerDocsSiteUrl;
    },
  },
  dataLabHangfire: {
    getUrl: () => `${getSystemApiUrl()}dlgw/jobs-dashboard`,
  },
  orderBooksHangfire: {
    getUrl: () => `${getSystemApiUrl()}ob/jobs-dashboard`,
  },
  rolodexHangfire: {
    getUrl: () => `${getSystemApiUrl()}dex/jobs-dashboard`,
  },
  wiresHangfire: {
    getUrl: () => `${getSystemApiUrl()}wr/jobs-dashboard`,
  },
};

export default externalRouteFactory;
