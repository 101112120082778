import { Entity, permissionsByEntity } from '@cmg/auth';

export enum PermissionLevel {
  READ = 'read',
  FULL = 'full',
}

export enum GroupName {
  Administration = 'Administration',
  CRM = 'CRM',
  Offerings = 'Offerings',
  Datalab = 'Datalab',
  OrderBook = 'Order Book',
  RegulatoryFilings = 'Regulatory Filings',
  SyndicateWires = 'Syndicate Wires',
  Settlement = 'Settlement',
  Firms = 'Firms',
  TradeRelease = 'Trade Release',
  SalesCredit = 'Sales Credit',
}

export enum EntityDisplay {
  GlobalAccount = 'Global Account',
  GlobalUser = 'Global User',
  GlobalRole = 'Global Role',
  GlobalApiKey = 'Global Api Key',
  ApiKey = 'Api Key',
  Firm = 'Firm',
  Account = 'Account',
  User = 'User',
  Role = 'Role',
  Team = 'Team',
  SignupToken = 'Signup Token',
  Conflict = 'Conflict',
  Contact = 'Contact',
  Offering = 'Offering',
  PublishedOffering = 'Published Offering',
  Ioi = 'Ioi',
  FundIoi = 'Fund Ioi',
  CustomSectors = 'Custom Sectors',
  ApiDocs = 'Api Docs',
  SharedReports = 'Shared Reports',
  SharedFollowedOfferings = 'Shared Followed Offerings',
  OrderBook = 'Order Book',
  InstitutionalIndication = 'Institutional Indications',
  InstitutionalAllocation = 'Institutional Allocations',
  RetailDemand = 'Retail Demand',
  RetailRetention = 'Retail Retentions',
  CoveredAccountsCrm = 'CRM Covered Accounts',
  CoveredAccountsInstitutionalIndication = 'Covered Accounts Institutional Indication',
  CoveredAccountsInstitutionalAllocation = 'Covered Accounts Institutional Allocation',
  RegulatoryFiling = 'Regulatory Filing',
  SyndicateWire = 'Syndicate Wire',
  Datalab = 'Datalab',
  DatalabUI = 'Datalab UI',
  CRM = 'CRM',
  CrmBasicInvestorFirm = 'CRM Basic Investor Firm',
  Company = 'Company',
  SystemDataLab = 'System DataLab',
  /** @deprecated OffPlatformOffering is deprecated, use `SystemDataLab` instead */
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OffPlatformOffering = 'System DataLab',
  InternationalOffering = 'International Offering',
  GlobalCrm = 'Global CRM',
  GlobalInstitutionalIndication = 'Global Institutional Indication',
  Underwriter = 'Underwriter',
  FullSyndicateLineUp = 'Full Syndicate Line Up',
  Designation = 'Designation',
  Expense = 'Expense',
  Stabilization = 'Stabilization',
  Accounting = 'Accounting',
  Letter = 'Letter',
  Trade = 'Trades',
  SalesCredit = 'Sales Credit',
  SyndicateMember = 'Syndicate Member',
  CertificateLibrary = 'Certificate Library',
  CertificateReview = 'Certificate Review',
  ATM = 'ATM',
  Converts = 'Converts',
  ATMSelldown = 'ATM Selldown',
  DLExcelDownload = 'DL Excel Download',
}

export type AccessLevel = PermissionLevel.READ | PermissionLevel.FULL;

export type PermissionDetail = {
  groupName: GroupName;
  permissionName: string;
  permissionLevel: PermissionLevel;
  name: string;
  entity: EntityDisplay;
};

export type UndefinedPermissionDetail = {
  groupName: string;
  permissionName: string;
  permissionLevel: string;
  name: string;
  entity: string;
};

export const permissionDisplay = {
  [permissionsByEntity.GlobalAccount.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Global Accounts',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.GlobalAccount.READ,
    entity: EntityDisplay[Entity.GlobalAccount],
  },
  [permissionsByEntity.GlobalAccount.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Global Accounts',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.GlobalAccount.FULL,
    entity: EntityDisplay[Entity.GlobalAccount],
  },
  [permissionsByEntity.GlobalUser.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Global Users',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.GlobalUser.READ,
    entity: EntityDisplay[Entity.GlobalUser],
  },
  [permissionsByEntity.GlobalUser.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Global Users',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.GlobalUser.FULL,
    entity: EntityDisplay[Entity.GlobalUser],
  },
  [permissionsByEntity.GlobalRole.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Global Roles',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.GlobalRole.READ,
    entity: EntityDisplay[Entity.GlobalRole],
  },
  [permissionsByEntity.GlobalRole.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Global Roles',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.GlobalRole.FULL,
    entity: EntityDisplay[Entity.GlobalRole],
  },
  [permissionsByEntity.SignupToken.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Signup Token',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SignupToken.READ,
    entity: EntityDisplay[Entity.SignupToken],
  },
  [permissionsByEntity.SignupToken.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Signup Token',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.SignupToken.FULL,
    entity: EntityDisplay[Entity.SignupToken],
  },
  [permissionsByEntity.GlobalApiKey.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Global Api Keys',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.GlobalApiKey.READ,
    entity: EntityDisplay[Entity.GlobalApiKey],
  },
  [permissionsByEntity.GlobalApiKey.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Global Api Keys',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.GlobalApiKey.FULL,
    entity: EntityDisplay[Entity.GlobalApiKey],
  },
  [permissionsByEntity.ApiKey.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Api Keys',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.ApiKey.READ,
    entity: EntityDisplay[Entity.ApiKey],
  },
  [permissionsByEntity.ApiKey.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Api Keys',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.ApiKey.FULL,
    entity: EntityDisplay[Entity.ApiKey],
  },
  [permissionsByEntity.Firm.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Firms',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Firm.READ,
    entity: EntityDisplay[Entity.Firm],
  },
  [permissionsByEntity.Firm.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Firms',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Firm.FULL,
    entity: EntityDisplay[Entity.Firm],
  },
  [permissionsByEntity.Account.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Accounts',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Account.READ,
    entity: EntityDisplay[Entity.Account],
  },
  [permissionsByEntity.Account.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Accounts',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Account.FULL,
    entity: EntityDisplay[Entity.Account],
  },
  [permissionsByEntity.User.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Users',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.User.READ,
    entity: EntityDisplay[Entity.User],
  },
  [permissionsByEntity.User.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Users',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.User.FULL,
    entity: EntityDisplay[Entity.User],
  },
  [permissionsByEntity.Role.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Roles',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Role.READ,
    entity: EntityDisplay[Entity.Role],
  },
  [permissionsByEntity.Role.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Roles',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Role.FULL,
    entity: EntityDisplay[Entity.Role],
  },
  [permissionsByEntity.Team.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Teams',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Team.READ,
    entity: EntityDisplay[Entity.Team],
  },
  [permissionsByEntity.Team.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Teams',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Team.FULL,
    entity: EntityDisplay[Entity.Team],
  },
  [permissionsByEntity.Conflict.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Conflicts',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Conflict.READ,
    entity: EntityDisplay[Entity.Conflict],
  },
  [permissionsByEntity.Conflict.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Conflicts',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Conflict.FULL,
    entity: EntityDisplay[Entity.Conflict],
  },
  [permissionsByEntity.Contact.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Contacts',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Contact.READ,
    entity: EntityDisplay[Entity.Contact],
  },
  [permissionsByEntity.Contact.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Contacts',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Contact.FULL,
    entity: EntityDisplay[Entity.Contact],
  },
  [permissionsByEntity.GlobalInstitutionalIndication.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Global Institutional Indication',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.GlobalInstitutionalIndication.READ,
    entity: EntityDisplay[Entity.GlobalInstitutionalIndication],
  },
  [permissionsByEntity.GlobalInstitutionalIndication.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Global Institutional Indication',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.GlobalInstitutionalIndication.FULL,
    entity: EntityDisplay[Entity.GlobalInstitutionalIndication],
  },
  [permissionsByEntity.GlobalCrm.READ]: {
    groupName: GroupName.Administration,
    permissionName: 'View Global CRM',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.GlobalCrm.READ,
    entity: EntityDisplay[Entity.GlobalCrm],
  },
  [permissionsByEntity.GlobalCrm.FULL]: {
    groupName: GroupName.Administration,
    permissionName: 'Manage Global CRM',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.GlobalCrm.FULL,
    entity: EntityDisplay[Entity.GlobalCrm],
  },
  [permissionsByEntity.Offering.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View Offerings',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Offering.READ,
    entity: EntityDisplay[Entity.Offering],
  },
  [permissionsByEntity.Offering.FULL]: {
    groupName: GroupName.Offerings,
    permissionName: 'Manage Offerings',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Offering.FULL,
    entity: EntityDisplay[Entity.Offering],
  },
  [permissionsByEntity.PublishedOffering.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View Published Offerings',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.PublishedOffering.READ,
    entity: EntityDisplay[Entity.PublishedOffering],
  },
  [permissionsByEntity.PublishedOffering.FULL]: {
    groupName: GroupName.Offerings,
    permissionName: 'Manage Published Offerings',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.PublishedOffering.FULL,
    entity: EntityDisplay[Entity.PublishedOffering],
  },
  [permissionsByEntity.SystemDataLab.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View Off Platform Offering Admin',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SystemDataLab.READ,
    entity: EntityDisplay[Entity.SystemDataLab],
  },
  [permissionsByEntity.SystemDataLab.FULL]: {
    groupName: GroupName.Offerings,
    permissionName: 'Manage Off Platform Offering Admin',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.SystemDataLab.FULL,
    entity: EntityDisplay[Entity.SystemDataLab],
  },
  [permissionsByEntity.FullSyndicateLineUp.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View Full Syndicate Line up',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.FullSyndicateLineUp.READ,
    entity: EntityDisplay[Entity.FullSyndicateLineUp],
  },
  [permissionsByEntity.InternationalOffering.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View International Offering',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.InternationalOffering.READ,
    entity: EntityDisplay[Entity.InternationalOffering],
  },
  [permissionsByEntity.ATM.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View ATM',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.ATM.READ,
    entity: EntityDisplay[Entity.Atm],
  },
  [permissionsByEntity.Converts.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View Converts',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Converts.READ,
    entity: EntityDisplay[Entity.Converts],
  },
  [permissionsByEntity.ATMSelldown.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View ATM Selldown',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.ATMSelldown.READ,
    entity: EntityDisplay[Entity.AtmSelldown],
  },
  [permissionsByEntity.Ioi.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View IOIs and Allocations',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Ioi.READ,
    entity: EntityDisplay[Entity.Ioi],
  },
  [permissionsByEntity.Ioi.FULL]: {
    groupName: GroupName.Datalab,
    permissionName: 'Manage IOIs and Allocations',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Ioi.FULL,
    entity: EntityDisplay[Entity.Ioi],
  },
  [permissionsByEntity.FundIoi.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View Fund IOIs and Allocations',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.FundIoi.READ,
    entity: EntityDisplay[Entity.FundIoi],
  },
  [permissionsByEntity.FundIoi.FULL]: {
    groupName: GroupName.Datalab,
    permissionName: 'Manage Fund IOIs and Allocations',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.FundIoi.FULL,
    entity: EntityDisplay[Entity.FundIoi],
  },
  [permissionsByEntity.Datalab.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View DataLab',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Datalab.READ,
    entity: EntityDisplay[Entity.Datalab],
  },
  [permissionsByEntity.DatalabUI.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View DataLab UI',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.DatalabUI.READ,
    entity: EntityDisplay[Entity.DatalabUI],
  },
  [permissionsByEntity.CustomSectors.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View Custom Sectors',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CustomSectors.READ,
    entity: EntityDisplay[Entity.CustomSectors],
  },
  [permissionsByEntity.CustomSectors.FULL]: {
    groupName: GroupName.Datalab,
    permissionName: 'Manage Custom Sectors',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.CustomSectors.FULL,
    entity: EntityDisplay[Entity.CustomSectors],
  },
  [permissionsByEntity.ApiDocs.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View Api Docs',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.ApiDocs.READ,
    entity: EntityDisplay[Entity.ApiDocs],
  },
  [permissionsByEntity.SharedReports.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View Shared Reports',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SharedReports.READ,
    entity: EntityDisplay[Entity.SharedReports],
  },
  [permissionsByEntity.SharedReports.FULL]: {
    groupName: GroupName.Datalab,
    permissionName: 'Manage Shared Reports',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.SharedReports.FULL,
    entity: EntityDisplay[Entity.SharedReports],
  },
  [permissionsByEntity.SharedFollowedOfferings.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View Shared Followed Offerings',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SharedFollowedOfferings.READ,
    entity: EntityDisplay[Entity.SharedFollowedOfferings],
  },
  [permissionsByEntity.DLExcelDownload.READ]: {
    groupName: GroupName.Datalab,
    permissionName: 'View Shared Followed Offerings',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.DLExcelDownload.READ,
    entity: EntityDisplay[Entity.DlExcelDownload],
  },
  [permissionsByEntity.OrderBook.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Order Book',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.OrderBook.FULL,
    entity: EntityDisplay[Entity.OrderBook],
  },
  [permissionsByEntity.OrderBook.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Order Book',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.OrderBook.READ,
    entity: EntityDisplay[Entity.OrderBook],
  },
  [permissionsByEntity.InstitutionalIndication.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Institutional Indication',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.InstitutionalIndication.READ,
    entity: EntityDisplay[Entity.InstitutionalIndication],
  },
  [permissionsByEntity.InstitutionalIndication.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Institutional Indication',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.InstitutionalIndication.FULL,
    entity: EntityDisplay[Entity.InstitutionalIndication],
  },
  [permissionsByEntity.InstitutionalAllocation.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Institutional Allocation',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.InstitutionalAllocation.READ,
    entity: EntityDisplay[Entity.InstitutionalAllocation],
  },
  [permissionsByEntity.RetailDemand.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Retail Demand',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.RetailDemand.READ,
    entity: EntityDisplay[Entity.RetailDemand],
  },
  [permissionsByEntity.RetailDemand.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Retail Demand',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.RetailDemand.FULL,
    entity: EntityDisplay[Entity.RetailDemand],
  },
  [permissionsByEntity.RetailRetention.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Retail Retention',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.RetailRetention.READ,
    entity: EntityDisplay[Entity.RetailRetention],
  },
  [permissionsByEntity.RetailRetention.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Retail Retention',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.RetailRetention.FULL,
    entity: EntityDisplay[Entity.RetailRetention],
  },
  [permissionsByEntity.InstitutionalAllocation.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Institutional Allocation',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.InstitutionalAllocation.FULL,
    entity: EntityDisplay[Entity.InstitutionalAllocation],
  },
  [permissionsByEntity.CoveredAccountsInstitutionalIndication.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Covered Accounts Institutional Indication',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CoveredAccountsInstitutionalIndication.READ,
    entity: EntityDisplay[Entity.CoveredAccountsInstitutionalIndication],
  },
  [permissionsByEntity.CoveredAccountsInstitutionalIndication.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Covered Accounts Institutional Indication',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.CoveredAccountsInstitutionalIndication.FULL,
    entity: EntityDisplay[Entity.CoveredAccountsInstitutionalIndication],
  },
  [permissionsByEntity.CoveredAccountsInstitutionalAllocation.READ]: {
    groupName: GroupName.OrderBook,
    permissionName: 'View Covered Accounts Institutional Allocation',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CoveredAccountsInstitutionalAllocation.READ,
    entity: EntityDisplay[Entity.CoveredAccountsInstitutionalAllocation],
  },
  [permissionsByEntity.CoveredAccountsInstitutionalAllocation.FULL]: {
    groupName: GroupName.OrderBook,
    permissionName: 'Manage Covered Accounts Institutional Allocation',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.CoveredAccountsInstitutionalAllocation.FULL,
    entity: EntityDisplay[Entity.CoveredAccountsInstitutionalAllocation],
  },
  [permissionsByEntity.RegulatoryFiling.READ]: {
    groupName: GroupName.RegulatoryFilings,
    permissionName: 'View Regulatory Filing',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.RegulatoryFiling.READ,
    entity: EntityDisplay[Entity.RegulatoryFiling],
  },
  [permissionsByEntity.RegulatoryFiling.FULL]: {
    groupName: GroupName.RegulatoryFilings,
    permissionName: 'Manage Regulatory Filing',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.RegulatoryFiling.FULL,
    entity: EntityDisplay[Entity.RegulatoryFiling],
  },
  [permissionsByEntity.SyndicateWire.READ]: {
    groupName: GroupName.SyndicateWires,
    permissionName: 'View Syndicate Wire',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SyndicateWire.READ,
    entity: EntityDisplay[Entity.SyndicateWire],
  },
  [permissionsByEntity.SyndicateWire.FULL]: {
    groupName: GroupName.SyndicateWires,
    permissionName: 'Manage Syndicate Wire',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.SyndicateWire.FULL,
    entity: EntityDisplay[Entity.SyndicateWire],
  },
  [permissionsByEntity.CRM.READ]: {
    groupName: GroupName.CRM,
    permissionName: 'View CRM',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CRM.READ,
    entity: EntityDisplay[Entity.CRM],
  },
  [permissionsByEntity.CoveredAccountsCrm.READ]: {
    groupName: GroupName.CRM,
    permissionName: 'View CRM Covered Accounts',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CoveredAccountsCrm.READ,
    entity: EntityDisplay[Entity.CoveredAccountsCrm],
  },
  [permissionsByEntity.CRM.FULL]: {
    groupName: GroupName.CRM,
    permissionName: 'Manage CRM',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.CRM.FULL,
    entity: EntityDisplay[Entity.CRM],
  },
  [permissionsByEntity.CrmBasicInvestorFirm.READ]: {
    groupName: GroupName.CRM,
    permissionName: 'View CRM Basic Investor Firm',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CrmBasicInvestorFirm.READ,
    entity: EntityDisplay[Entity.CrmBasicInvestorFirm],
  },
  [permissionsByEntity.CrmBasicInvestorFirm.FULL]: {
    groupName: GroupName.CRM,
    permissionName: 'Manage CRM Basic Investor Firm',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.CrmBasicInvestorFirm.FULL,
    entity: EntityDisplay[Entity.CrmBasicInvestorFirm],
  },
  [permissionsByEntity.Company.READ]: {
    groupName: GroupName.Firms,
    permissionName: 'View Company',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Company.READ,
    entity: EntityDisplay[Entity.Company],
  },
  [permissionsByEntity.Company.FULL]: {
    groupName: GroupName.Firms,
    permissionName: 'Manage Company',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Company.FULL,
    entity: EntityDisplay[Entity.Company],
  },
  [permissionsByEntity.Underwriter.READ]: {
    groupName: GroupName.Firms,
    permissionName: 'View Underwriter',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Underwriter.READ,
    entity: EntityDisplay[Entity.Underwriter],
  },
  [permissionsByEntity.Designation.READ]: {
    groupName: GroupName.Settlement,
    permissionName: 'View Designation',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Designation.READ,
    entity: EntityDisplay[Entity.Designation],
  },
  [permissionsByEntity.Designation.FULL]: {
    groupName: GroupName.Settlement,
    permissionName: 'Manage Designation',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Designation.FULL,
    entity: EntityDisplay[Entity.Designation],
  },
  [permissionsByEntity.Expense.READ]: {
    groupName: GroupName.Settlement,
    permissionName: 'View Expense',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Expense.READ,
    entity: EntityDisplay[Entity.Expense],
  },
  [permissionsByEntity.Expense.FULL]: {
    groupName: GroupName.Settlement,
    permissionName: 'Manage Expense',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Expense.FULL,
    entity: EntityDisplay[Entity.Expense],
  },
  [permissionsByEntity.Stabilization.READ]: {
    groupName: GroupName.Settlement,
    permissionName: 'View Stabilization',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Stabilization.READ,
    entity: EntityDisplay[Entity.Stabilization],
  },
  [permissionsByEntity.Stabilization.FULL]: {
    groupName: GroupName.Settlement,
    permissionName: 'Manage Stabilization',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Stabilization.FULL,
    entity: EntityDisplay[Entity.Stabilization],
  },
  [permissionsByEntity.Accounting.READ]: {
    groupName: GroupName.Settlement,
    permissionName: 'View Accounting',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Accounting.READ,
    entity: EntityDisplay[Entity.Accounting],
  },
  [permissionsByEntity.Accounting.FULL]: {
    groupName: GroupName.Settlement,
    permissionName: 'Manage Accounting',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Accounting.FULL,
    entity: EntityDisplay[Entity.Accounting],
  },
  [permissionsByEntity.Letter.READ]: {
    groupName: GroupName.Settlement,
    permissionName: 'View Letter',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Letter.READ,
    entity: EntityDisplay[Entity.Letter],
  },
  [permissionsByEntity.Letter.FULL]: {
    groupName: GroupName.Settlement,
    permissionName: 'Manage Letter',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Letter.FULL,
    entity: EntityDisplay[Entity.Letter],
  },
  [permissionsByEntity.SalesCredit.READ]: {
    groupName: GroupName.SalesCredit,
    permissionName: 'View Sales Credit',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SalesCredit.READ,
    entity: EntityDisplay[Entity.SalesCredit],
  },
  [permissionsByEntity.SalesCredit.FULL]: {
    groupName: GroupName.SalesCredit,
    permissionName: 'Manage Sales Credit',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.SalesCredit.FULL,
    entity: EntityDisplay[Entity.SalesCredit],
  },
  [permissionsByEntity.Trade.READ]: {
    groupName: GroupName.TradeRelease,
    permissionName: 'View Trades',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.Trade.READ,
    entity: EntityDisplay[Entity.Trade],
  },
  [permissionsByEntity.Trade.FULL]: {
    groupName: GroupName.TradeRelease,
    permissionName: 'Manage Trades',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.Trade.FULL,
    entity: EntityDisplay[Entity.Trade],
  },
  [permissionsByEntity.SyndicateMember.READ]: {
    groupName: GroupName.Offerings,
    permissionName: 'View Syndicate Member',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.SyndicateMember.READ,
    entity: EntityDisplay[Entity.SyndicateMember],
  },
  [permissionsByEntity.CertificateLibrary.READ]: {
    groupName: GroupName.CRM,
    permissionName: 'View Certificate Library',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CertificateLibrary.READ,
    entity: EntityDisplay[Entity.CertificateLibrary],
  },
  [permissionsByEntity.CertificateReview.READ]: {
    groupName: GroupName.CRM,
    permissionName: 'View Certificate Review',
    permissionLevel: PermissionLevel.READ,
    name: permissionsByEntity.CertificateReview.READ,
    entity: EntityDisplay[Entity.CertificateReview],
  },
  [permissionsByEntity.CertificateReview.FULL]: {
    groupName: GroupName.CRM,
    permissionName: 'Manage Certificate Review',
    permissionLevel: PermissionLevel.FULL,
    name: permissionsByEntity.CertificateReview.FULL,
    entity: EntityDisplay[Entity.CertificateReview],
  },
};
