import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { apiTypes } from '@cmg/common';
import { DomainObject, PageLayout } from '@cmg/design-system';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { bindActionCreators } from 'redux';
import { useDebouncedCallback } from 'use-debounce';

import { InvestorCoverageFilters } from '../../../../common/api/rolodexApiClient';
import { useDocumentTitle } from '../../../../common/hooks/useDocumentTitle/useDocumentTitle';
import routeFactory from '../../../../common/util/routeFactory';
import { IdentityPageContent } from '../../../../design-system/IdentityPageContent';
import {
  fetchInvestorCoverage,
  fetchProfile,
  selectError,
  selectInvestorCoverage,
  selectInvestorCoverageLoading,
  selectPagination,
  selectProfile,
  selectProfileLoading,
  selectSubmitting,
  updateProfile,
} from '../../profile/ducks';
import { useInvestorCoverageCheck } from '../../profile/hooks/useInvestorCoverageCheck';
import ProfileDomainHighlights from './domain-highlights/ProfileDomainHighlights';
import { ProfileGridContent } from './grid-content/ProfileGridContent';
import ChangePasswordRoute from './password-change/ChangePasswordRoute';
import { ProfileRouteSkeleton } from './ProfileRouteSkeleton';

const mapStateToProps = state => ({
  profile: selectProfile(state),
  investorCoverage: selectInvestorCoverage(state),
  pagination: selectPagination(state),
  isProfileLoading: selectProfileLoading(state),
  isInvestorCoverageLoading: selectInvestorCoverageLoading(state),
  submitting: selectSubmitting(state),
  error: selectError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchProfile,
      updateProfile,
      fetchInvestorCoverage,
    },
    dispatch
  ),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type Props = StateProps & DispatchProps;

export const ProfileRouteComponent: React.FC<Props> = ({
  profile,
  investorCoverage,
  submitting,
  error,
  isProfileLoading,
  isInvestorCoverageLoading,
  pagination,
  actions,
}) => {
  const { oidcUserAccountType, oidcUserIdp, userPermissions } = useAuth();
  const canViewHangfire = checkPermissions(userPermissions, [
    permissionsByEntity.HangfireDashboard.READ,
  ]);

  const [filters, setFilters] = React.useState<InvestorCoverageFilters>({});

  const debouncedFetch = useDebouncedCallback(actions.fetchInvestorCoverage, 300);

  const { canFetchInvestorCoverage } = useInvestorCoverageCheck(profile?.configurationStatus);

  useDocumentTitle(routeFactory.profile.getDocumentTitle());

  const handleChangePage = (params: apiTypes.ListParams) => {
    actions.fetchInvestorCoverage(params);
  };

  const handleChangeFilter = (filters: InvestorCoverageFilters, debounce?: boolean) => {
    debounce
      ? debouncedFetch({ page: 1, ...filters })
      : actions.fetchInvestorCoverage({ page: 1, ...filters });
    setFilters(filters);
  };

  React.useEffect(() => {
    actions.fetchProfile();
  }, [actions]);

  React.useEffect(() => {
    if (canFetchInvestorCoverage) {
      actions.fetchInvestorCoverage({ page: 1, perPage: 25 });
    }
  }, [actions, canFetchInvestorCoverage]);

  if (isProfileLoading) {
    return <ProfileRouteSkeleton />;
  }

  return (
    <PageLayout header={<DomainObject domainHighlights={<ProfileDomainHighlights />} />}>
      <IdentityPageContent
        gridContent={
          <Fragment>
            <Route
              exact
              path={routeFactory.changePassword.routePath}
              component={ChangePasswordRoute}
            />
            <ProfileGridContent
              profile={profile}
              isProfileSubmitting={submitting}
              onProfileSubmit={profile => actions.updateProfile({ profile })}
              profileSubmitError={error}
              oidcUserIdp={oidcUserIdp}
              oidcUserAccountType={oidcUserAccountType || null}
              showHangfireLinks={canViewHangfire}
              showInvestorCoverage={canFetchInvestorCoverage}
              investorCoverage={investorCoverage}
              isLoadingInvestorCoverage={isInvestorCoverageLoading}
              investorCoveragePagination={pagination}
              investorCoverageFilters={filters}
              onInvestorCoverageChangeFilters={handleChangeFilter}
              onInvestorCoverageChangePage={handleChangePage}
            />
          </Fragment>
        }
      />
    </PageLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRouteComponent);
