import {
  FirmCategoryType,
  InvestorFirmAdminRead as CrmInvestorFirm,
  RecordStatus,
} from '@capital-markets-gateway/api-client-rolodex';
import { apiTypes, DataGrid, Icon, SelectField, TextInputField } from '@cmg/common';
import { identitySelectors } from '@cmg/e2e-selectors';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { useDebouncedCallback } from 'use-debounce';

import { CrmInvestorFirmsFilters } from '../../../../../common/api/rolodexApiClient';
import { UUID } from '../../../../../types/common';
import {
  firmCategoryOptions,
  firmStatusOptions,
  LinkedStatus,
  linkedStatusOptions,
} from '../../../../../types/domain/entity-matcher/constants';
import { createColumns } from './CrmInvestorFirmListGridColumns';

export const SFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0;

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    flex-direction: row;
  }
`;

export const SField = styled.div`
  margin: 0 0 10px 0;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaQuery.xlargeUp} {
    margin: 0 10px 10px 0;
  }
`;

export const SIconWrapper = styled.div`
  margin-left: 10px;
`;

type Values = {
  searchText?: string | null;
  linkedStatus?: LinkedStatus | null;
  firmType?: FirmCategoryType | null;
};
type OwnProps = {
  crmIntegrationId: UUID;
  loading: boolean;
  clients: CrmInvestorFirm[];
  pagination?: apiTypes.Pagination;
  filters: CrmInvestorFirmsFilters;
  onChangeFilters: (params: CrmInvestorFirmsFilters, debounce?: boolean) => void;
  onChangePage: (params: apiTypes.ListParams) => void;
  onDownload?: () => void;
};
export type Props = OwnProps & FormikProps<Values>;

/**
 * Displays the a list of the firms in an Account's CRM data.
 */
export const CrmInvestorFirmListComponent: React.FC<Props> = ({
  crmIntegrationId,
  loading,
  clients,
  pagination,
  handleSubmit: handleFormikSubmit,
  onChangePage,
  onDownload,
}) => {
  const debouncedHandleSubmit = useDebouncedCallback(() => handleFormikSubmit(), 400);

  const handleSubmit = (debounce?: boolean) =>
    debounce ? debouncedHandleSubmit() : handleFormikSubmit();

  return (
    <React.Fragment>
      <DataGrid<CrmInvestorFirm>
        onGridReady={params => {
          params.api.resetRowHeights();
        }}
        renderFilters={() => (
          <SFilters>
            <SField>
              <TextInputField
                name="searchText"
                data-test-id={identitySelectors.rolodex.crmInvestorFirmListSearchInput.testId}
                fullWidth
                placeholder="Search by CRM Key, CRM Name, CMG Entity Key and Name..."
                prefix={
                  <SIconWrapper>
                    <Icon name="search" />
                  </SIconWrapper>
                }
                onChange={() => handleSubmit(true)}
              />
            </SField>
            <SField>
              <SelectField
                name="firmStatus"
                data-test-id={
                  identitySelectors.rolodex.crmInvestorFirmListInvestorStatusSelect.testId
                }
                fullWidth
                placeholder="Filter by CRM Status..."
                options={firmStatusOptions}
                onChange={() => handleSubmit()}
              />
            </SField>
            <SField>
              <SelectField
                name="linkedStatus"
                data-test-id={identitySelectors.rolodex.crmInvestorFirmListLinkStatusSelect.testId}
                fullWidth
                placeholder="Filter by Link Status..."
                options={linkedStatusOptions}
                onChange={() => handleSubmit()}
              />
            </SField>
            <SField>
              <SelectField
                name="firmType"
                data-test-id={
                  identitySelectors.rolodex.crmInvestorFirmListInvestorCategorySelect.testId
                }
                fullWidth
                placeholder="Filter by Investor Category..."
                options={firmCategoryOptions}
                onChange={() => handleSubmit()}
              />
            </SField>
          </SFilters>
        )}
        pagination={
          pagination
            ? {
                page: pagination.activePage,
                perPage: pagination.perPage,
              }
            : undefined
        }
        totalPages={pagination ? pagination.totalPages : 0}
        onPaginationChange={({ page, perPage, orderField, orderDirection }) => {
          const params = {
            page,
            perPage,
            orderField,
            ...(orderField
              ? {
                  orderDirection:
                    orderDirection === 'asc'
                      ? apiTypes.SortDirection.ASC
                      : apiTypes.SortDirection.DESC,
                }
              : {}),
          };

          onChangePage(params);
        }}
        loading={loading}
        extended={{
          withMargin: false,
          onDownload: onDownload,
          downloadTitle: 'Download CRM Investor Firms',
        }}
        columns={createColumns(crmIntegrationId)}
        rows={clients}
        resizeBy="grid"
        gridOptions={{
          suppressCellSelection: true,
          suppressRowClickSelection: true,
        }}
      />
    </React.Fragment>
  );
};

const FormikCrmInvestorFirmList = withFormik<OwnProps, Values>({
  enableReinitialize: true,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onChangeFilters(values);
  },
  mapPropsToValues: ({ filters }) => {
    return {
      searchText: filters.searchText || '',
      linkedStatus: filters.linkedStatus || undefined,
      firmType: filters.firmType || undefined,
      firmStatus: filters.firmStatus ?? RecordStatus.EFFECTIVE,
    };
  },
})(CrmInvestorFirmListComponent);

export default FormikCrmInvestorFirmList;
